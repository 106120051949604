.artPopup {
  background: rgb(255, 246, 216, 0.9);
  width: 100%;
  min-height: 900px;
  max-width: 1920px;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.artPopup .close {
  position: absolute;
  right: -20px;
  top: -20px;
  width: 75px;
  height: 75px;
}

/* .closeIconArtPopup {
  max-width: 250px;
} */

.artPopup .close > img {
  max-width: 250px;
  height: 75px !important;
}

.artPopup .close:hover {
  cursor: pointer;
}

.artPopup .container {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.artPopup .container img {
  max-width: 100%;
  height: 810px;
  margin-right: 42px;
}

.closeIconArt {
  max-width: 80px !important;
}

.artPopup .container .content {
  display: flex;
  flex-direction: column;
}

.artPopup .container .content .name {
  font-size: 40px;
  line-height: 47px;
  font-weight: bold;
  font-family: "BloggerSans-Bold";
  color: #93272c;
  margin-bottom: 7px;
  text-align: left;
}

.artPopup .container .content .title {
  width: 100%;
  text-align: left;
  font-size: 25px;
  line-height: 27px;
  color: #93272c;
  font-family: "GothamBook";
  margin-bottom: 70px;
}

.artPopup .container .content .btn {
  width: 250px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #93272c;
  color: #fff6d8;
  font-size: 25px;
  line-height: 29px;
  font-weight: bold;
  font-family: "BloggerSans-Bold";
  border-radius: 10px;
  pointer-events: none;
}

.artPopup .container .content .btn:hover {
  cursor: pointer;
}

@media (max-width: 1300px) {
  .artPopup .container img {
    width: 400px;
    height: 720px;
  }

  .artPopup .container .content .name {
    font-size: 35px;
    line-height: 42px;
  }

  .artPopup .container .content .title {
    font-size: 23px;
    line-height: 25px;
    margin-bottom: 50px;
  }

  .artPopup .container .content .btn {
    width: 220px;
    height: 50px;
    font-size: 23px;
    line-height: 25px;
  }
}

@media (max-width: 975px) {
  .artPopup .container img {
    width: 337px;
    height: 600px;
  }

  .artPopup .container .content .name {
    font-size: 30px;
    line-height: 36px;
  }

  .artPopup .container .content .title {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 40px;
  }

  .artPopup .container .content .btn {
    width: 200px;
    height: 45px;
    font-size: 21px;
    line-height: 23px;
  }

  .artPopup .close {
    right: -20px;
    top: 80px;
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 650px) {
  .artPopup .container {
    flex-direction: column;
    align-items: center;
  }

  .artPopup .container img {
    width: 337px;
    height: 600px;
    margin-right: 0;
    margin-bottom: 28px;
  }

  .artPopup .container .content {
    align-items: center;
  }

  .artPopup .container .content .name {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }

  .artPopup .container .content .title {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 32px;
    text-align: center;
  }

  .artPopup .container .content .btn {
    width: 180px;
    height: 40px;
    font-size: 20px;
    line-height: 21px;
  }

  .artPopup .close {
    right: -60px;
    top: -20px;
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 480px) {
  .artPopup .close {
    right: 15px;
    top: -60px;
  }
}

@media (max-width: 400px) {
  .artPopup .container img {
    width: 300px;
    height: 580px;
  }
  .closeIconArt {
    max-width: 80px !important;
  }

  .artPopup .close {
    height: 30px;
    right: 15px;
    top: -65px;
  }
}

@media (max-width: 375px) {
  .artPopup .close {
    top: -60px;
    right: 15px;
  }
}

@media (max-width: 350px) {
  .artPopup .container img {
    width: 270px;
    height: 540px;
  }
}
