.artistPopup{
  width: 100%;
  min-height: 900px;
  max-width: 1920px;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  background: rgb(255, 246, 216, 0.9);
}
.artistPopup .video{
  width: 647px;
  height: 808px;
  object-fit: fill;
}
.artistPopup .container{
  position: relative;
}
.artistPopup  .close{
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  z-index: 15555;
  display: flex;
  justify-content: center;
  align-items: center;
}
.artistPopup  .close:hover{
  cursor: pointer;
}
.artistPopup  .close img{
  pointer-events: none;
}


@media (max-width: 1200px) {
  .artistPopup .video{
    width: 550px;
    height: 650px;
  }
}

@media (max-width: 750px) {
  .artistPopup .video{
    width: 450px;
    height: 530px;
  }
}

@media (max-width: 550px) {
  .artistPopup .video{
    width: 337px;
    height: 420px;
  }
}

@media (max-width: 400px) {
  .artistPopup .video{
    width: 300px;
    height: 390px;
  }
}

@media (max-width: 340px) {
  .artistPopup .video{
    width: 280px;
  }
}
