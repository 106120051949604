 header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  color: #AB2328;
  background: #FFF6D8;
  font-family: 'GothamBook';
  font-size: 18px;
  line-height: 19px;
  box-sizing: border-box;
  padding-left: 540px;
  padding-right: 540px;
}
 header > a:hover{
  cursor: pointer;
}
header > div:hover{
  cursor: pointer;
}

@media (max-width:1920px) and (min-width:1550px) {
    header{
      padding-left: calc((100vw - 900px) / 2);
      padding-right: calc((100vw - 900px) / 2);
    }
}

@media (max-width: 1550px) {
    header{
      padding-left: 150px;
      padding-right: 150px;
    }
  }

@media (max-width: 1350px) {
    header{
      padding-left: 75px;
      padding-right: 75px;
      font-size: 17px;
    }
  }

@media (max-width: 1230px) {
      header{
        padding-left: 50px;
        padding-right: 50px;
        font-size: 16px;
      }
  }

@media (max-width: 900px) {
        header{
          padding-left: 0px;
          padding-right: 0px;
        }
    }

@media (max-width: 768px) {
      header{
        flex-wrap: wrap;
        height: max-content;
        justify-content: center;
        padding-top: 30px;
      }
      header a{
        width: 215px;
        height: 48px;
        font-size: 20px;
        line-height: 21px;
        background: #93272C;
        color: #FFF6D8;
        font-family: 'BloggerSans-Bold';
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        margin-bottom: 15px;
      }
      header div{
        width: 215px;
        height: 48px;
        font-size: 20px;
        line-height: 21px;
        background: #93272C;
        color: #FFF6D8;
        font-family: 'BloggerSans-Bold';
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        margin-bottom: 15px;
      }
  }

  @media (max-width: 550px) {
        header a{
          width: calc( 50% - 10px);
          height: 40px;
          font-size: 15px;
          line-height: 16px;
          margin-bottom: 10px;
          margin-right: 8px;
        }
        header div{
          width: calc( 50% - 10px);
          height: 40px;
          font-size: 15px;
          line-height: 16px;
          margin-bottom: 10px;
          margin-right: 8px;
        }
    }
