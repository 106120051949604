.votePage {
  width: 100%;
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.votePage .desktopLandingTitlee {
  max-width: 280px;
  top: 25px;
  position: absolute;
  right: -37px;
}

.closeIcon {
  max-width: 100%;
}

.votePage .close {
  position: absolute;
  top: 32px;
  right: 125px;
}

.votePage .close:hover {
  cursor: pointer;
}

.votePage .headTitle {
  margin-top: 42px;
}

.votePage .textSubtitle {
  margin-bottom: 66px;
}

.votePage .brush1 {
  position: absolute;
  right: -195px;
  top: 465px;
}

.votePage .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
}

.votePage .form .inputHolder {
  border: 3px solid #B58500;
  border-radius: 10px;
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.votePage .form .inputHolder[inputErr="true"] {
  border: 3px solid #93272C;
}

.votePage .form .inputHolder img {
  margin-left: 27.5px;
  margin-right: 40px;
  max-width: 100%;
  height: auto;
}

.votePage .form .inputHolder input {
  font-size: 30px;
  line-height: 43px;
  color: #B58500;
  font-family: 'GothamBook';
  width: auto;
  margin-right: 27.5px;
  flex-grow: 1;
  border: none;
  background: none;
}

.votePage .form .inputHolder input:focus {
  outline: none;
}

.votePage .form .inputHolder input::placeholder {
  opacity: 0.5;
  color: #B58500;
}

.votePage .form .checkboxHolder {
  margin-top: 10px;
  display: flex;
  color: #4F2C1D;
  font-size: 25px;
  line-height: 27px;
  font-family: 'GothamBook';
  align-items: center;
  margin-bottom: 50px;
  margin-left: 0;
  margin-right: auto;
}

.votePage .form .checkboxHolder input {
  width: 30px;
  height: 30px;
  margin-right: 17px;
  accent-color: #93272C;
  margin-left: 0;
}

.votePage .form .btn {
  width: 378px;
  height: 80px;
  color: #FFF6D8;
  background: #93272C;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  line-height: 41px;
  font-family: 'BloggerSans-Bold';
  font-weight: bold;
  border-radius: 10px;
  margin-bottom: 37px;
}

.votePage .form .btn:hover {
  cursor: pointer;
}

.votePage .form .err {
  color: #4F2C1D;
  font-size: 25px;
  line-height: 27px;
  font-family: 'GothamBook';
  padding: 15.5px 21.5px 21.5px 21.5px;
  text-align: left;
  margin-bottom: 60px;
}

.votePage .form .err[isEmpty="true"] {
  display: none;
}

.votePage .form .err:has(> p) {
  border: 3px solid #93272C;
}

.votePage .form .err p {
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 1660px) {
  .votePage .brush1 {
    position: absolute;
    right: -325px;
    top: 465px;
  }
}

@media (max-width: 1550px) {
  .votePage .form {
    width: 600px;
  }

  .votePage .desktopLandingTitlee {
    max-width: 240px;
    top: 20px;
    position: absolute;
    right: -37px;
  }

  .votePage .form .inputHolder {
    height: 70px;
  }

  .votePage .form .inputHolder input {
    font-size: 27px;
    line-height: 39px;
    margin-right: 20px;
  }

  .votePage .form .btn {
    width: 330px;
    height: 65px;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 37px;
  }

  .votePage .form .err {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 50px;
  }
}

@media (max-width: 1350px) {
  .votePage .headTitle {
    margin-bottom: 60px;
  }

  .votePage .desktopLandingTitlee {
    max-width: 210px;
    top: 15px;
    position: absolute;
    right: -37px;
  }

  .votePage .textSubtitle {
    margin-bottom: 30px;
  }

  .votePage .form {
    width: 500px;
  }

  .votePage .form .inputHolder {
    height: 60px;
  }

  .votePage .form .inputHolder input {
    font-size: 25px;
    line-height: 36px;
  }

  .votePage .form .btn {
    width: 300px;
    height: 60px;
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 25px;
  }

  .votePage .form .err {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 40px;
  }
}

@media (max-width: 975px) {
  .votePage .headTitle {
    margin-bottom: 50px;
  }

  .votePage .form {
    width: 450px;
  }

  .votePage .desktopLandingTitlee {
    max-width: 210px;
    top: 15px;
    position: absolute;
    right: -37px;
  }

  .votePage .form .inputHolder {
    height: 50px;
  }

  .votePage .form .inputHolder img {
    margin-left: 20px;
    margin-right: 25px;
    max-width: 35px;
    height: auto;
  }

  .votePage .form .inputHolder input {
    font-size: 22px;
    line-height: 30px;
  }

  .votePage .form .btn {
    width: 270px;
    height: 50px;
    font-size: 26px;
    line-height: 30px;
  }

  .votePage .form .err {
    font-size: 18px;
    line-height: 21px;
  }

  .votePage .close {
    width: 60px;
    height: 60px;
    right: 55px;
  }
}

@media (max-width: 550px) {
  .votePage .form {
    width: 100%;
  }

  .votePage .form .inputHolder img {
    margin-left: 15px;
    margin-right: 20px;
    max-width: 25px;
    height: auto;
  }

  .votePage .desktopLandingTitlee {
    max-width: 210px;
    top: 10px;
    position: absolute;
    right: -37px;
  }

  .votePage .form .inputHolder input {
    font-size: 18px;
    line-height: 25px;
  }

  .votePage .form .btn {
    width: 240px;
    height: 45px;
    font-size: 20px;
    line-height: 24px;
  }

  .votePage .form .err {
    font-size: 17px;
    line-height: 20px;
  }

  .votePage .close {
    width: 40px;
    height: 40px;
    right: 45px;
    top: 25px;
  }
}

@media (max-width: 450px) {

  .votePage .close {
    width: 30px;
    height: 30px;
    top: 25px;
  }

  .votePage .desktopLandingTitlee {
    max-width: 210px;
    top: 15px;
    position: absolute;
    right: -37px;
  }

  /* .votePage .desktopLandingTitle {
    right: -15px !important;
  } */
}

@media (max-width: 400px) {
  .votePage .form .inputHolder {
    height: 40px;
  }

  .votePage .desktopLandingTitlee {
    max-width: 210px;
    top: 15px;
    position: absolute;
    right: -37px;
  }

  .votePage .close {
    width: 30px;
    height: 30px;
    top: 25px;
  }
}