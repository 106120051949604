.landing {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background-image: url("../../images/wallBackground.png");
  background-size: auto;
  position: relative;
  overflow-x: hidden;
  padding-left: 240px;
  padding-right: 240px;
  overflow:hidden;
}

.landing .bck {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -3;
}

.headTitle {
  font-size: 65px;
  line-height: 77px;
  color: #93272c;
  font-family: "BloggerSans-Bold";
  position: relative;
  width: max-content;
  margin-bottom: 100px;
}

.headTitle .desktopLandingTitle {
  position: absolute;
  right: -37px;
  top: 15px;
  max-width: 100%;
}

.textSubtitle {
  font-size: 25px;
  line-height: 27px;
  font-family: "GothamBook";
  color: #4f2c1d;
  text-align: left;
  padding-left: 78px;
  padding-right: 78px;
  margin-bottom: 130px;
}

.landing .brush1 {
  position: absolute;
  top: 845px;
  right: -190px;
}

/* .landing .brush2 {
  position: absolute;
  left: -110px;
  top: 1820px;
} */

.landing .brush3 {
  position: absolute;
  right: -260px;
  top: 3950px;
}

.landing .brush4 {
  position: absolute;
  left: -140px;
  top: 5550px;
}

@media (max-width: 1920px) and (min-width: 1550px) {
  .landing {
    /* padding-left: calc((100vw - 1440px) / 2);
    padding-right: calc((100vw - 1440px) / 2);  if they want the first versio with bigger padding*/
    padding-left: 55.5px;
    padding-right: 55.5px;
  }
  .landing .brush1 {
    position: absolute;
    top: 1015px;
    right: -190px;
  }
  .landing .brush3 {
    position: absolute;
    right: -290px;
    top: 4130px;
  }
  /* .landing .brush2 {
    position: absolute;
    left: -150px;
    top: 1930px;
  } */
  .landing .brush4 {
    position: absolute;
    left: -190px;
    /* top: 7230px; */
    top: 7510px;
  }
}
@media (max-width: 1850px) {
  .landing .brush1 {
    top: 955px;
  }
}
@media (max-width: 1800px) {
  .landing .brush1 {
    right: -230px;
    top: 960px;
  }
  @media (max-width: 1750px) {
    .landing .brush1 {
      right: -230px;
      top: 910px;
    }
  }
  @media (max-width: 1620px) {
    .landing .brush1 {
      top: 880px;
    }
  }
  @media (max-width: 1600px) {
    .landing .brush1 {
      right: -230px;
      top: 840px;
    }
  }
  /* .landing .brush2 {
    left: -180px;
  } */

  .landing .brush3 {
    top: 4000px;
    right: -300px;
  }

  .landing .brush4 {
    left: -210px;
    top: 7420px;
  }
}

@media (max-width: 1550px) {
  .landing {
    padding-left: 55.5px;
    padding-right: 55.5px;
  }

  .landing .headTitle {
    font-size: 55px;
    line-height: 68px;
    width: max-content;
    margin-bottom: 100px;
  }

  .landing .textSubtitle {
    font-size: 21px;
    line-height: 24px;
  }

  .landing .brush1 {
    top: 705px;
  }

  /* .landing .brush2 {
    top: 1670px;
  } */

  .landing .brush3 {
    top: 3830px;
  }

  .landing .brush4 {
    top: 7120px;
  }
}
@media (max-width: 1450px) {
  .landing .brush4 {
    left: -210px;
    top: 7180px;
  }
}
@media (max-width: 1426px) {
  .landing .brush3 {
    top: 3860px;
  }
}

@media (max-width: 1400px) {
  .landing .brush3 {
    display: none;
  }

  .landing .brush4 {
    display: none;
  }
}

@media (max-width: 1350px) {
  .landing .headTitle {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 80px;
  }

  .landing .textSubtitle {
    font-size: 20px;
    line-height: 22px;
  }

  .landing .brush1 {
    right: -360px;
  }

  /* .landing .brush2 {
    left: -310px;
  } */

  .landing .brush3 {
    right: -430px;
  }

  .landing .brush4 {
    left: -340px;
  }

  .landing .brush1 {
    display: none;
  }

  /* .landing .brush2 {
    display: none;
  } */
}

@media (max-width: 1115px) {
  .landing {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 975px) {
  .landing .headTitle {
    font-size: 42px;
    line-height: 56px;
    margin-bottom: 70px;
  }

  .landing .textSubtitle {
    font-size: 18px;
    line-height: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .landing .brush1 {
    display: none;
  }

  /* .landing .brush2 {
    display: none;
  } */

  .landing .brush3 {
    display: none;
  }

  .landing .brush4 {
    display: none;
  }
}

@media (max-width: 550px) {
  .landing {
    padding-left: 30px;
    padding-right: 30px;
  }

  .landing .headTitle {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 65px;
    width: auto;
  }

  .landing .textSubtitle {
    font-size: 17px;
    line-height: 18px;
  }
}

@media (max-width: 425px) {
  .landing {
    padding-left: 10px;
    padding-right: 10px;
  }
}
